import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../../components/Layout';
import PageHeader from '../../components/PageHeader';
import ModularBlocks from '../../components/ModularBlocks';

const PlantsForBusinessDefaultPageTemplate = ({
  data: {
    datoCmsPlantsForBusinessPage: { seoMetaTags, title, modularBlocks },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <PageHeader
        heading={title}
        breadcrumbs={[
          { slug: 'plants-for-business', text: 'Plants for Business' },
        ]}
      />
      <ModularBlocks items={modularBlocks} />
    </main>
  </Layout>
);

export const PlantsForBusinessDefaultPageTemplateQuery = graphql`
  query PlantsForBusinessDefaultPageTemplateQuery($id: String!) {
    datoCmsPlantsForBusinessPage(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      modularBlocks {
        ...AccordionModularBlockFragment
        ...CompanyReviewsModularBlockFragment
        ...ContactFormModularBlockFragment
        ...ContainedImageModularBlockFragment
        ...ContentModularBlockFragment
        ...ExternalVideoModularBlockFragment
        ...FullWidthImageModularBlockFragment
        ...HighlightedTextModularBlockFragment
        ...IconCtasModularBlockFragment
        ...ImageContentModularBlockFragment
        ...ImageGalleryModularBlockFragment
        ...ImageLinkCardsModularBlockFragment
        ...InternalVideoModularBlockFragment
        ...TabsModularBlockFragment
        ...TeamProfilesModularBlockFragment
        ...TestimonialsModularBlockFragment
      }
    }
  }
`;

export default PlantsForBusinessDefaultPageTemplate;
